import React, { useState, useEffect, useRef } from "react";
import { useMediaQuery } from "@mui/material";
import {
  Card,
  Button,
  OtpContainer,
  OtpInputContainer,
  ResendOtpLink,
  SuccessMessage,
  Label,
  InputField,
  ErrorText,
  Select,
  PayButton,
  LoginButton,
} from "../Dialog/PaymentDialogStyles";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useTheme } from "@mui/material/styles";
import { open_api_with_user_token } from "../../Utils/network";
import { PhoneInput } from "react-international-phone";
import "react-phone-input-2/lib/style.css";
import {
  fetchCountryCode,
  validatePhoneNumber,
  validateEmail,
  handleOTPChange,
  handleKeyDown,
  handlePhoneNumberChange,
  handleEmailChange,
  requestOTP,
  validateOTP,
  addAlert,
  removeAlert,
  fetchProfileTypes,
  checkUserInfoExists,
  fetchUserDetails,
  validateGoogleLoginWithToken,
} from "../Dialog/helper";
import useAuth from "../Hook/useAuth";
import { GoogleLogin } from "@react-oauth/google";
import CustomAlert from "../AlertDialog/CustomAlert";
import { useNavigate } from "react-router-dom";
import LoadingOverlay from "../Dialog/LoadingOverlay";
import UserProfileForm from "./UserProfileForm";
import googleLoginIcon from "../../Assets/login/googleLogin.svg";
import { useGoogleLogin } from "@react-oauth/google";
import { getCountryCode } from "../../Utils/network";
import "react-international-phone/style.css";
import styled from "styled-components";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

const CustomPhoneInput = styled(PhoneInput)`
  flex: 1;
  .react-international-phone-input-container {
    display: flex;
    align-items: center;
    border: none; /* Remove internal borders */
    padding: 0;
    height: 40px; /* Set a height that matches the container */
    background: none; /* No background to blend with container */
  }

  .react-international-phone-input {
    border: none;
    outline: none;
    width: 100%;
    padding: 8px;
    font-size: 13px;
  }

  .react-international-phone-country-selector {
    border-right: 1px solid #ddd; /* Divider between flag and input */
    display: flex;
    align-items: center;
    background-color: #f9f9f9; /* Flag section background */
    height: 100%;
    border-radius: 8px 0 0 8px; /* Rounded corners for the flag section */
  }
`;

const LoginCard = ({
  setEnablePayButton,
  bypass,
  updateIsUserInfoExists,
  updateBearerToken,
}) => {
  const [useEmail, setUseEmail] = useState(false);
  const [usePhone, setUsePhone] = useState(false);
  const [isVerified, setVerified] = useState(false);
  const [otpRequested, setOtpRequested] = useState(false);
  const [otp, setOtp] = useState(Array(4).fill(""));
  const [otpValidated, setOtpValidated] = useState(false);
  const [firstName, setfirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const theme = useTheme();
  const [isLoading, setIsLoading] = useState(false);
  const [firstNameError, setfirstNameError] = useState(false);
  const [lastNameError, setLastNameError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [phoneNumberError, setPhoneNumberError] = useState(false);
  const [countryCode, setCountryCode] = useState("in");
  const [isdCode, setIsdCode] = useState("");
  const [countrycodeISO, setcountrycodeISO] = useState("IN");
  const inputRefs = useRef([]);
  const [alerts, setAlerts] = useState([]);
  const [showAlert, setShowAlert] = useState(false);
  const [resentOTP, setResentOTP] = useState(false);
  const [countdown, setCountdown] = useState(0);
  const [profileTypeId, setProfileTypeId] = useState(0);
  const [profileType, setProfileType] = useState("");
  const [profileTypeError, setProfileTypeError] = useState(false);
  const [profileTypes, setProfileTypes] = useState([]);
  const {
    isAuthenticated,
    userName,
    userId,
    bearerToken,
    setIsAuthenticated,
    setUserId,
    setBearerToken,
    setUserName,
  } = useAuth();
  const [isUserInfoExists, setIsUserInfoExists] = useState(false);
  const [message, setMessage] = useState("");
  const navigate = useNavigate();
  const [isHideLoginButton, setIsHideLoginButton] = useState(false);
  const [phoneWithCountryCode, setPhoneWithCountryCode] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState(null);
  const [city, setCity] = useState("");
  const [dateOfBirthError, setDateOfBirthError] = useState(false);
  const [cityError, setCityError] = useState(false);

  dayjs.extend(utc);
  dayjs.extend(timezone);

  useEffect(() => {}, [isAuthenticated]);
  useEffect(() => {
    updateUserDetails();
  }, [isAuthenticated, bearerToken, userId, userName]);

  const updateUserDetails = async () => {
    if (isAuthenticated && bearerToken && userId) {
      // Ensure all necessary data is available
      const sessionUseEmail = localStorage.getItem("useEmail");
      const sessionUsePhone = localStorage.getItem("usePhone");
      setUseEmail(sessionUseEmail === "true");
      setUsePhone(sessionUsePhone === "true");
      setBearerToken(bearerToken);
      setUserName(userName);
      setUserId(userId);
      const userInfo = await fetchUserDetails(bearerToken, userId);
      setPhoneNumber(userInfo?.phone ? userInfo?.phone : "");
      setEmail(userInfo?.email);
      setfirstName(userInfo?.first_name);
      setLastName(userInfo?.last_name);
      setIsUserInfoExists(
        checkUserInfoExists(
          userInfo?.first_name,
          userInfo?.last_name,
          userInfo?.email,
          userInfo?.phone,
          sessionUseEmail
        )
      );
    }
  };

  useEffect(() => {
    if (countdown > 0) {
      const timer = setTimeout(() => {
        setCountdown(countdown - 1);
      }, 1000);
      return () => clearTimeout(timer);
    } else if (countdown === 0 && resentOTP) {
      setResentOTP(false);
    }
  }, [countdown, resentOTP]);

  useEffect(() => {
    const fetchTypes = async () => {
      var accessToken = bearerToken;
      if (accessToken === "") {
        accessToken = localStorage.getItem("bearerToken");
      }
      updateBearerToken(accessToken);
      await fetchProfileTypes(accessToken, setProfileTypes);
    };
    fetchTypes();
  }, [bearerToken]);

  useEffect(() => {
    const fetchCountry = async () => {
      const countryCode = await getCountryCode();
      setCountryCode(countryCode);
    };
    fetchCountry();
  }, []);

  const handleAddAlert = (title, message, type, autoClose = false) => {
    addAlert(title, message, type, autoClose, setAlerts);
  };

  const handleRemoveAlert = (id) => {
    removeAlert(id, setAlerts);
  };

  const handleSubmitOTP = async () => {
    await handleValidateOTP();
  };

  const handleRequestOTP = () => {
    setEmailError(false);
    setPhoneNumberError(false);
    if (
      useEmail
        ? validateEmail(email, setEmailError, setShowAlert, handleAddAlert)
        : validatePhoneNumber(
            phoneWithCountryCode,
            setPhoneNumberError,
            setShowAlert,
            handleAddAlert,
            setcountrycodeISO
          )
    ) {
      requestOTP(
        phoneNumber,
        isdCode,
        setOtpRequested,
        setShowAlert,
        handleAddAlert
      );
      // Request OTP logic here
    } else {
    }
  };

  const handleResendOTP = () => {
    handleRequestOTP();
    setResentOTP(true);
    setCountdown(30);
  };

  const handlePhoneInputChange = (value, data) => {
    const sanitizedPhoneValue = data.inputValue.replace(/[^0-9]/g, "");
    handlePhoneNumberChange(
      sanitizedPhoneValue,
      data,
      setIsdCode,
      setPhoneNumber,
      setPhoneNumberError,
      setPhoneWithCountryCode
    );
  };

  const handleEmailInputChange = (e) => {
    handleEmailChange(e, setEmail, setEmailError);
  };

  const handlePhoneNumberInputChange = (e) => {
    const value = e.target.value;
    const phoneRegex = /^[0-9]*$/;
    if (value === "" || phoneRegex.test(value)) {
      setPhoneNumber(value);
      setPhoneNumberError(value.trim() === "");
    }
  };

  const handlefirstNameChange = (e) => {
    const value = e.target.value;
    setfirstName(value);

    const regex = /^[A-Za-z\s]*$/;
    if (regex.test(value)) {
      setfirstNameError(value.trim() === "");
    } else {
      setfirstNameError(true);
    }
  };

  const handlelastNameChange = (e) => {
    const value = e.target.value;
    // Allow input updates, even with numbers
    setLastName(value);

    // Validate the input value
    const regex = /^[A-Za-z\s]*$/;
    if (regex.test(value)) {
      setLastNameError(value.trim() === ""); // Check for empty field
    } else {
      setLastNameError(true); // Set error if it contains invalid characters
    }
  };

  const handleOTPInputChange = (e, index) => {
    handleOTPChange(e, index, otp, setOtp, inputRefs);
  };

  const handleOTPKeyDown = (e, index) => {
    handleKeyDown(e, index, otp, inputRefs);
  };

  const handleProfileTypeChange = (e) => {
    const selectedId = e.target.value;
    const selectedType = profileTypes.find(
      (type) => type.id.toString() === selectedId
    );
    if (selectedType) {
      setProfileType(selectedType.title);
      setProfileTypeId(selectedType.id);
    } else {
      setProfileType("");
    }
    setProfileTypeError(false);
  };

  const handleValidateOTP = async () => {
    setUseEmail(false);
    setUsePhone(true);
    await validateOTP(
      otp,
      isdCode,
      phoneNumber,
      setOtpValidated,
      setVerified,
      setUserId,
      setBearerToken,
      setShowAlert,
      handleAddAlert,
      setfirstName,
      setLastName,
      setUserName,
      setEmail,
      setPhoneNumber,
      setIsAuthenticated,
      setIsUserInfoExists,
      setEnablePayButton,
      updateIsUserInfoExists
    );
  };

  const handleGoogleLoginSuccess = (credentialResponse) => {
    setUseEmail(true);
    setUsePhone(false);
    validateGoogleLoginWithToken(
      credentialResponse.credential,
      setOtpValidated,
      setVerified,
      setUserId,
      setBearerToken,
      setShowAlert,
      handleAddAlert,
      setEmail,
      setPhoneNumber,
      setfirstName,
      setLastName,
      setUserName,
      setIsAuthenticated,
      setIsUserInfoExists,
      true,
      setEnablePayButton,
      updateIsUserInfoExists
    );
  };

  const handleDateOfBirthChange = (newDate) => {
    if (newDate) {
      // Convert to IST (Indian Standard Time)
      const dateInIST = dayjs(newDate).tz("Asia/Kolkata").format("YYYY-MM-DD");

      setDateOfBirth(dateInIST); // Set the formatted date (without time) in state
    } else {
      setDateOfBirth(null); // Handle no date selected
    }
    setDateOfBirth(newDate);
    setDateOfBirthError(!newDate);
  };

  const handleCityChange = (e) => {
    setCity(e.target.value);
    setCityError(!e.target.value);
  };

  // const handleLoginError = () => {
  //   console.error("Google Login Failed");
  // };

  // const googleLogin = useGoogleLogin({
  //   onSuccess: handleGoogleLoginSuccess,
  //   onError: handleLoginError,
  // });

  const handleGoogleLoginError = () => {
    setShowAlert(true);
    handleAddAlert(
      "Login Error",
      "Failed to login with google. Please try again",
      "error",
      true
    );
  };

  const validateForm = () => {
    let valid = true; // Initialize valid flag

    // Regex patterns
    const nameRegex = /^[A-Za-z\s]+$/;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!firstName || !nameRegex.test(firstName)) {
      setfirstNameError(true);
      valid = false;
    } else {
      setfirstNameError(false);
    }

    if (!lastName || !nameRegex.test(lastName)) {
      setLastNameError(true);
      valid = false;
    } else {
      setLastNameError(false);
    }

    if (useEmail) {
      let isPhoneValid = validatePhoneNumber(
        phoneWithCountryCode,
        setPhoneNumberError,
        setShowAlert,
        handleAddAlert,
        setcountrycodeISO
      );
      if (!isPhoneValid) {
        setPhoneNumberError(true);
        valid = false;
      } else {
        setPhoneNumberError(false);
      }
    } else {
      let isPhoneValid = validatePhoneNumber(
        phoneWithCountryCode,
        setPhoneNumberError,
        setShowAlert,
        handleAddAlert,
        setcountrycodeISO
      );
      if (!isPhoneValid) {
        setPhoneNumberError(true);
        valid = false;
      } else {
        setPhoneNumberError(false);
      }

      if (!email || !emailRegex.test(email)) {
        setEmailError(true);
        valid = false;
      } else {
        setEmailError(false);
      }
    }

    if (!profileType) {
      setProfileTypeError(true);
      valid = false;
    } else {
      setProfileTypeError(false);
    }

    if (!dateOfBirth) {
      setDateOfBirthError(true);
      valid = false;
    } else {
      setDateOfBirthError(false);
    }

    // Validate City (Check if city is non-empty)
    const country_code = sessionStorage.getItem("country_code");
    if (country_code === "in" || countryCode === "IN") {
      if (!city || city.trim() === "") {
        setCityError(true);
        valid = false;
      } else {
        setCityError(false);
      }
    }

    return valid;
  };

  const fetchWorkshopDetailsAndPay = async () => {
    let valid = true;
    if (!isUserInfoExists) {
      valid = validateForm();
    }

    if (valid) {
      setIsLoading(true);
      try {
        let userDetailsUpdated;
        if (!isUserInfoExists) {
          const result = await sumbitUserDetails();
          userDetailsUpdated = result.success;

          if (result.alertShown) {
            return;
          }
        }

        if (userDetailsUpdated || isUserInfoExists) {
          if (bypass) {
            setIsUserInfoExists(true);
            updateIsUserInfoExists(true);
            setEnablePayButton(true);
            setIsHideLoginButton(true);
            setIsLoading(false);
          } else {
            await new Promise((resolve) => setTimeout(resolve, 500));
            navigate("/");
            window.location.reload();
          }
        } else {
          setShowAlert(true);
          handleAddAlert(
            "Input Error",
            "There was an issue while submitting the form",
            "error",
            true
          );
          setIsLoading(false);
        }
      } catch (error) {
        console.error("Error in flow:", error);
        setIsLoading(false);
      }
    } else {
      setShowAlert(true);
      handleAddAlert(
        "Input Error",
        "Please fill in all mandatory fields before proceeding",
        "error",
        true
      );
      setIsLoading(false);
    }
  };

  const sumbitUserDetails = async () => {
    let registerAsId, title;
    switch (profileType.toLowerCase()) {
      case "mom":
        title = "Mrs";
        break;
      case "dad":
        title = "Mr";
        break;
      case "grand pa":
        title = "Mr";
        break;
      case "grand ma":
        title = "Mrs";
        break;
      default:
        title = "Mr/Mrs";
    }

    const formattedDateOfBirth = dayjs(dateOfBirth).format("YYYY-MM-DD");
    const country_code = sessionStorage.getItem("country_code");
    const userDetails = {
      id: userId,
      phone: phoneNumber,
      first_name: firstName,
      last_name: lastName,
      email: email,
      register_as_id: profileTypeId,
      title: title,
      isd_code: isdCode,
      date_of_birth: formattedDateOfBirth,
      ...(country_code === "in" && { city: city }),
    };
    try {
      const res = await open_api_with_user_token(bearerToken).patch(
        `/profiles/update_profile/`,
        userDetails
      );

      //console.log(res);
      if (res?.status === 200 || res?.status === 201 || res?.status === 204) {
        localStorage.setItem("userName", firstName + " " + lastName);
        setUserName(firstName + " " + lastName);
        return { success: true, alertShown: false };
      } else if (res?.status === 400) {
        if (res?.data?.email) {
          setShowAlert(true);
          handleAddAlert("Input Error", res?.data?.email, "error", true);
          setIsLoading(false);
          return { success: false, alertShown: true };
        } else if (res?.data?.phone) {
          setShowAlert(true);
          handleAddAlert("Input Error", res?.data?.phone, "error", true);
          setIsLoading(false);
          return { success: false, alertShown: true };
        } else {
          setShowAlert(true);
          handleAddAlert("Input Error", res?.data, "error", true);
          setIsLoading(false);
          return { success: false, alertShown: true };
        }
      }
    } catch (err) {
      console.error("Error in sumbitUserDetails:", err);
      return { success: false, alertShown: false };
    }
    return { success: false, alertShown: false };
  };

  return (
    <>
      {isLoading && <LoadingOverlay message={message} />}
      {!isAuthenticated ? (
        <Card>
          {otpValidated ? (
            <>
              {!isUserInfoExists ? (
                <UserProfileForm
                  useEmail={useEmail}
                  email={email}
                  isdCode={isdCode}
                  countrycodeISO={countrycodeISO}
                  CustomPhoneInput={CustomPhoneInput}
                  phoneNumber={phoneNumber}
                  usePhone={usePhone}
                  firstName={firstName}
                  lastName={lastName}
                  dateOfBirth={dateOfBirth}
                  city={city}
                  profileType={profileType}
                  profileTypes={profileTypes}
                  firstNameError={firstNameError}
                  lastNameError={lastNameError}
                  phoneNumberError={phoneNumberError}
                  emailError={emailError}
                  dateOfBirthError={dateOfBirthError}
                  cityError={cityError}
                  profileTypeError={profileTypeError}
                  handlefirstNameChange={handlefirstNameChange}
                  handlelastNameChange={handlelastNameChange}
                  handlePhoneNumberInputChange={handlePhoneInputChange}
                  handleEmailInputChange={handleEmailInputChange}
                  handleDateOfBirthChange={handleDateOfBirthChange}
                  handleCityChange={handleCityChange}
                  handleProfileTypeChange={handleProfileTypeChange}
                />
              ) : (
                <>
                  <SuccessMessage>
                    <CheckCircleIcon color="success" />
                    <h3>You are logged in as {userName}</h3>
                  </SuccessMessage>
                </>
              )}
            </>
          ) : otpRequested ? (
            <>
              <h3>A 4-digit code is sent to {`+${isdCode} ${phoneNumber}`}</h3>
              <OtpInputContainer>
                {Array.from({ length: 4 }).map((_, index) => (
                  <input
                    key={index}
                    type="text"
                    maxLength="1"
                    value={otp[index]}
                    onChange={(e) => handleOTPInputChange(e, index)}
                    onKeyDown={(e) => handleOTPKeyDown(e, index)}
                    ref={(el) => (inputRefs.current[index] = el)}
                  />
                ))}
              </OtpInputContainer>
              <Button onClick={handleSubmitOTP}>Submit</Button>
              <div style={{ textAlign: "center", marginTop: "20px" }}>
                {resentOTP ? (
                  <div>
                    <p>Resend OTP in {countdown} seconds</p>
                  </div>
                ) : (
                  <div>
                    <ResendOtpLink onClick={handleResendOTP}>
                      Resend OTP
                    </ResendOtpLink>
                    <p style={{ marginTop: "8px", textAlign: "center" }}>
                      Didn't receive a code? Click on Resend to receive OTP
                    </p>
                  </div>
                )}
              </div>
            </>
          ) : (
            <>
              <h3>Verify using Phone Number</h3>
              <OtpContainer
                style={{
                  borderColor: phoneNumberError ? "red" : "#ccc",
                }}
              >
                <CustomPhoneInput
                  defaultCountry={countryCode}
                  value={phoneNumber}
                  disableDialCodeAndPrefix={true}
                  onChange={handlePhoneInputChange}
                  placeholder="Enter your phone number"
                />
              </OtpContainer>

              {phoneNumberError && (
                <ErrorText>Please enter a valid phone number</ErrorText>
              )}

              <Button onClick={handleRequestOTP}>Request OTP</Button>

              {/* Centered "OR" Divider */}
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  textAlign: "center",
                  margin: "20px 0",
                }}
              >
                <hr style={{ flex: 1 }} />
                <span style={{ padding: "0 10px", color: "#666" }}>OR</span>
                <hr style={{ flex: 1 }} />
              </div>

              {/* <div style={{ display: "flex", justifyContent: "center" }}>
                <button
                  onClick={() => googleLogin()} // Trigger login on button click
                  style={{
                    backgroundColor: "transparent", // Or any custom color
                    border: "none",
                    cursor: "pointer",
                    padding: "0", // Ensure no extra padding
                  }}
                >
                  <img
                    src={googleLoginIcon} // Path to your SVG
                    alt="Google Login"
                    style={{ width: "200px", height: "50px" }} // Adjust size as per your needs
                  />
                </button>
              </div> */}

              <div style={{ display: "flex", justifyContent: "center" }}>
                <GoogleLogin
                  onSuccess={handleGoogleLoginSuccess}
                  onError={handleGoogleLoginError}
                />
              </div>
            </>
          )}
        </Card>
      ) : (
        <Card>
          {!isUserInfoExists ? (
            <UserProfileForm
              useEmail={useEmail}
              email={email}
              isdCode={isdCode}
              countrycodeISO={countrycodeISO}
              CustomPhoneInput={CustomPhoneInput}
              phoneNumber={phoneNumber}
              usePhone={usePhone}
              firstName={firstName}
              lastName={lastName}
              dateOfBirth={dateOfBirth}
              city={city}
              profileType={profileType}
              profileTypes={profileTypes}
              firstNameError={firstNameError}
              lastNameError={lastNameError}
              phoneNumberError={phoneNumberError}
              emailError={emailError}
              dateOfBirthError={dateOfBirthError}
              cityError={cityError}
              profileTypeError={profileTypeError}
              handlefirstNameChange={handlefirstNameChange}
              handlelastNameChange={handlelastNameChange}
              handlePhoneNumberInputChange={handlePhoneInputChange}
              handleEmailInputChange={handleEmailInputChange}
              handleDateOfBirthChange={handleDateOfBirthChange}
              handleCityChange={handleCityChange}
              handleProfileTypeChange={handleProfileTypeChange}
            />
          ) : (
            <SuccessMessage>
              <CheckCircleIcon color="success" />
              <h3>You are logged in as {userName}</h3>
            </SuccessMessage>
          )}

          {bypass ? (
            <>
              {(isAuthenticated && isUserInfoExists) || isHideLoginButton ? (
                <></>
              ) : (
                <LoginButton
                  className="payButton"
                  onClick={fetchWorkshopDetailsAndPay}
                  bypass={bypass}
                >
                  Login
                </LoginButton>
              )}
            </>
          ) : (
            <>
              <LoginButton
                className="payButton"
                onClick={fetchWorkshopDetailsAndPay}
                bypass={bypass}
              >
                {isUserInfoExists ? "Continue" : "Login"}
              </LoginButton>
            </>
          )}
        </Card>
      )}
      {showAlert &&
        alerts.map((alert) => (
          <CustomAlert
            key={alert.id}
            title={alert.title}
            message={alert.message}
            onClose={() => handleRemoveAlert(alert.id)}
            autoClose={alert.autoClose}
            type={alert.type}
          />
        ))}
    </>
  );
};

export default LoginCard;
