import React, { useEffect, useRef, useState } from "react";
import styles from "./index.module.css";
import { Button } from "@mui/material";
import bgheart from "../../Assets/expert_page/bgHeart.svg";
import ReactPlayer from "react-player";
import workshopbgone from "../../Assets/expert_page/workshopBackgroung.png";
import workshopbgtwo from "../../Assets/expert_page/workshopbg_2.svg";
// import workshopbgone from "../../assets/expert_page/workshopBackgroung.png";
// import workshopbgtwo from "../../assets/expert_page/workshopbg_2.svg";
import WorkshopCard from "../../Components/WorkshopCard/WorkshopCard";
import ClientreviewCard from "../../Components/ClientreviewCard/ClientreviewCard";
import SpecialitiesCard from "../../Components/SpecialitiesCard/SpecialitiesCard";
import BlogsCard from "../../Components/BlogsCard/BlogsCard";
import DownloadeApp from "../../Components/DownloadeApp/DownloadeApp";
import JoinUsForm from "../../Components/JoinUsForm/JoinUsForm";
// import { open_api } from "../../Utils/Network";
import ExpertBlogSlider from "../../Components/ExpertBlogslider/ExpertBlogSlider";
import video from "../../Assets/videos/videos.mp4";
import childbBgtop from "../../Assets/expert_page/childbgmtop.svg";
import childbBgbott from "../../Assets/expert_page/childbgmbottom.svg";
import CourseDetailsPage from "../CourseDetailsPage/CourseDetailsPage";
// import childbBgtop from "../../assets/expert_page/childbgmtop.svg"
// import childbBgbott from "../../assets/expert_page/childbgmbottom.svg"
import {
  open_api,
  open_api_without_auth,
  getCountryCode,
} from "../../Utils/network";
import ExpertDetailsWorkshop from "../../Components/ExpertDetailsWorkshop/ExpertDetailsWorkshop";
import outerRing from "../../Assets/expert_page/expertouterRing.png";
import defultImg from "../../Assets/expert_page/defultimg.png";
import ExpertCards from "../../Components/ExpertCards/ExpertCards";
import ExpertListingPage from "../ExpertListingPage/ExpertListingPage";
import BookSessionDialog from "../../Components/Dialog/BookSessionDialog";
import { useParams, useNavigate } from "react-router-dom";
import ExpertReview from "../../Components/ExpertReview/ExpertReview";
import { fetchCountryCode } from "../../Components/Dialog/helper";

export function isImageUrl(url) {
  return /\.(jpeg|jpg|gif|png|svg)$/.test(url);
}

function ExpertDetailsPage() {
  const [expertDetails, setExpertDetails] = useState({});
  const [educationDetails, setEducationDetails] = useState([]);
  const [speciality, setSpeciality] = useState([]);
  const [workshopDetails, setWorkshopDetails] = useState([]);
  const [expertShowcase, setExpertShowCase] = useState([]);
  const { slug } = useParams();
  const [workshopSlug, setWorkshopSlug] = useState("");
  const navigate = useNavigate();
  const [countryCode, setCountryCode] = useState("in");
  // console.log(slug, " alfkoi89779");
  const playerRef = useRef(null);
  var urlLastPart = window.location.href.substring(
    window.location.href.lastIndexOf("/") + 1
  );
  const [open, setOpen] = useState(false);
  const [packages, setPackages] = useState([]);

  useEffect(() => {
    const fetchCountry = async () => {
      const countryCode = await getCountryCode();
      setCountryCode(countryCode);
    };
    fetchCountry();
  }, []);

  useEffect(() => {
    if (expertDetails?.id) {
      fetchPackages(expertDetails.id);
      getExpertShow(expertDetails.id);
    }
  }, [expertDetails]);

  const fetchPackages = async (expertId) => {
    try {
      const response = await open_api.get(
        `/session/package_list?expert=${expertId}`
      );
      setPackages(response?.data?.data);
      //console.log(response?.data?.data);
    } catch (error) {
      console.error("Error fetching packages:", error);
    }
  };

  const handleBuyNow = (workshopSlug) => {
    navigate(`/workshop/${workshopSlug}`);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    getExpertDetails();
  }, [slug]);

  const getExpertDetails = () => {
    open_api
      .get(`profiles/experts/${slug}/`)
      .then((res) => {
        // console.log(res?.data?.data);
        if (res?.status == 200 || res?.status == 201 || res?.status == 204) {
          setExpertDetails(res?.data);
          setEducationDetails(res?.data?.eductioninfo);
          setSpeciality(res?.data?.speciality);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  // console.log(expertDetails?.id, "urlsjsdfkuh");

  useEffect(() => {
    if (expertDetails?.hero_workshop) {
      getWorkshop(expertDetails?.hero_workshop);
    }
  }, [expertDetails?.hero_workshop]);
  const getWorkshop = (id) => {
    open_api
      .get(`home/workshop/${id}/`)
      .then((res) => {
        console.log(res?.data, "getWorkshop");
        if (res?.status == 200 || res?.status == 201 || res?.status == 204) {
          setWorkshopDetails(res?.data?.data);
          setWorkshopSlug(res?.data?.data?.slug);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const isImage = isImageUrl(workshopDetails?.file);

  // console.log(abc, "isImageUrl")
  // expert show case
  // useEffect(() => {
  //   getExpertShow();
  // },[])

  const getExpertShow = (id) => {
    open_api_without_auth
      .get(`profiles/experts/showcase?expert=${id}`)
      .then((res) => {
        // console.log(res?.data?.results, "getExpertShow");
        setExpertShowCase(res?.data?.results);
      })
      .catch((err) => console.log(err));
  };
  return (
    <>
      <section className={styles.expertHeader}>
        <div className={styles.containerLeft}>
          <div className={styles.bgwithcolortext}>
            <span>{expertDetails?.specialist_tagline}</span>
          </div>
          <h1 className={styles.expertName}>
            {expertDetails?.user?.first_name} {expertDetails?.user?.last_name}
          </h1>
          {/* <h5 className={styles.expertCol}>M.D in child psychology</h5> */}
          <h5 className={styles.expertCol}>{educationDetails[0]?.degree}</h5>
          <p className={styles.expertDesc}>
            {/* A child psychologist that has more than 12 years of experience in
            child understanding. */}
            {expertDetails?.about}
          </p>
          <Button
            variant="contained"
            className={`${styles.allActionBtn} ${
              packages && packages.length === 0 ? styles.greyBtn : ""
            }`}
            onClick={handleClickOpen}
            disabled={packages && packages.length === 0}
          >
            Book an appointment{" "}
          </Button>
          <BookSessionDialog
            open={open}
            onClose={handleClose}
            expertDetails={expertDetails}
            packages={packages}
          />
        </div>
        <div className={styles.containerRight}>
          <div className={styles.imgContainer}>
            <img src={outerRing} alt="" />
            <div className={styles.expertImg}>
              <img
                src={expertDetails?.image ? expertDetails?.image : ""}
                alt="epert"
              />
            </div>
          </div>
        </div>
        <div className={styles.bgHeart}>
          <img src={bgheart} alt="" />
        </div>
      </section>

      <section className={styles.childbeheviour}>
        <div className={styles.childleftContainer}>
          {isImage ? (
            <img
              src={workshopDetails?.file}
              className={styles.innerImageDisplay}
            ></img>
          ) : (
            <ReactPlayer
              controls={false}
              playing={true}
              muted={true}
              loop={true}
              ref={(player) => {
                playerRef.current = player;
              }}
              config={{
                file: {
                  attributes: {
                    controlsList: "nodownload",
                  },
                },
              }}
              // onSeek={3000}
              progressInterval={1000}
              url={workshopDetails?.file}
              // url="https://demo.unified-streaming.com/k8s/features/stable/video/tears-of-steel/tears-of-steel.ism/.m3u8"
              // width="100%"
              // height="500px"
              className={styles.innerVideoDisplay}
              style={{
                margin: "0 auto",
                backgroundColor: "#000",
                borderRadius: "10px",
                overflow: "hidden",
              }}
            />
          )}
        </div>
        <div className={styles.childRightContainer}>
          <div className={styles.bgwithcolortext}>
            <span>Join workshop</span>
          </div>
          <h2 className={styles.secondheading}>
            {/* Child Behaviour and Understanding */}
            {workshopDetails?.title}
          </h2>
          <p className={styles.ChildDescription}>
            {/* I specialize in understanding and addressing the unique emotional
            and developmental needs of children. With a deep understanding of
            child psychology, I aim to create a safe and supportive environment
            where children can express themselves freely.{" "} */}
            {workshopDetails?.about_workshop}
          </p>
          <Button
            variant="contained"
            className={styles.allActionBtn}
            onClick={() => handleBuyNow(workshopDetails?.slug)}
          >
            Join workshop
          </Button>
        </div>
      </section>
      <section className={styles.countersboxContainer}>
        <div className={styles.counterBox}>
          {expertShowcase?.map((v, i) => {
            return (
              <div className={styles.cBox} key={i}>
                <h2>{v?.value}</h2>
                <p>{v?.key}</p>
              </div>
            );
          })}
          {/* <div className={styles.cBox}>
            <h2>4.2</h2>
            <p>Ratings</p>
          </div>
          <div className={styles.cBox}>
            <h2>200+</h2>
            <p>Happy Parents</p>
          </div>
          <div className={styles.cBox}>
            <h2> 06</h2>
            <p>Cities </p>
          </div>
          <div className={styles.cBox}>
            <h2>10k</h2>
            <p>Courses</p>
          </div>
          <div className={styles.cBox}>
            <h2>100+</h2>
            <p>Reviews</p>
          </div> */}
        </div>
      </section>

      <section
        className={styles.workShopsContainer}
        // style={{ backgroundImage: `url(${workshopbgone})` }}
      >
        <div className={styles.WSContainer}>
          <div className={styles.wstopText}>
            <span>Courses</span>
          </div>
          <h2 className={styles.shopsName}>
            {expertDetails?.user?.first_name} {expertDetails?.user?.last_name}’s
            workshops
          </h2>
          <p className={styles.workSContainer}>
            {/* Having 3 PhD in child Understanding, she specialises in many
            different aspects of psychology. She has also mastered in couple
            parenting therapy and help parents in maintaining the intimacy they
            might have lost during childbirth and raising */}
            {expertDetails?.workshop_tagline}
          </p>
        </div>
      </section>
      <div className={styles.onlyforbg}>
        {" "}
        {/*  bagground Color Changes*/}
        <section
          className={styles.workSslider}
          // style={{ backgroundImage: `url(${workshopbgtwo})` }}
        >
          <div className={styles.InnerSliderCon}>
            <ExpertDetailsWorkshop slug={expertDetails?.id} />
            {/* <WorkshopCard  /> */}
          </div>
        </section>
        <section className={styles.clientReviews}>
          <h2 className={styles.CRHeading}>What my clients say</h2>
          <div className={styles.InnerSlider}>
            <ExpertReview expertId={expertDetails?.id} />
          </div>
        </section>
        <section className={styles.specialityContainer}>
          <div className={styles.spTopContainer}>
            <div className={styles.wstopText}>
              <span>Her Strength</span>
            </div>
            <h2 className={styles.spHeading}>Specialities</h2>
            <p className={styles.spDesc}>
              {/* Having 3 PhD in child Understanding, she specialises in many
              different aspects of psychology. She has also mastered in couple
              parenting therapy and help parents in maintaining the intimacy
              they might have lost during childbirth and raising */}
              {expertDetails?.specialist_tagline}
            </p>
          </div>
          <div className={styles.spSliderCOntainer}>
            <SpecialitiesCard data={speciality} />
          </div>
        </section>
        {/* <section className={styles.BlogContainer}>
          <h2 className={styles.blogheading}>Browse through my Blogs</h2>
          <div className={styles.blogsInnerContainer}>
           
            <ExpertBlogSlider />
          </div>
        </section> */}
        {/* <DownloadeApp /> */}
        <div style={{ margin: "50px 0" }}>
          <JoinUsForm />
        </div>
      </div>
    </>
  );
}

export default ExpertDetailsPage;
