import React from "react";
import styles from "./index.module.css";
import EbookDialogContent from "../../Components/PopUp/EbookDialogContent";
import backgroundImage from "../../Assets/popup/backgroundImage2.png";
import myburgoLogo from "../../Assets/Logo_icons/ebookMyburgoLogo.svg"; // Ensure correct path
import topicsToTeachCover from "../../Assets/popup/topic-for-kids-cover.png";

function LandingPage() {
  return (
    <div className={styles.mainContainer}>
      {/* Logo Section */}
      <div className={styles.logoContainer}>
        <img src={myburgoLogo} alt="Myburgo Logo" className={styles.logo} />
      </div>

      <div className={styles.cardContainer}>
        {/* Left side - Image */}
        <div
          className={styles.cardImage}
          style={{ backgroundImage: `url(${topicsToTeachCover})` }}
        />

        {/* Right side - EbookDialogContent */}
        <div className={styles.cardContent}>
          <EbookDialogContent
            type={"rahela ebook"}
            hidePopup={false}
            hidebg={true}
          />
        </div>
      </div>
    </div>
  );
}

export default LandingPage;
