import logo from "./logo.svg";
import "./App.css";
import LandingPage from "./Pages/LandingPage/LandingPage";
import Mainroutes from "./Approuter/MainRouter";
import theme from "./Utils/theme";
import { ThemeProvider } from "@mui/material/styles";
import { PopupProvider } from "../src/Components/PopUp/PopupContext";
import PopupDialog from "../src/Components/PopUp/PopupDialog";
import { DialogProvider } from "./Components/Dialog/DialogContext";
function App() {
  const currentSlug = window.location.pathname.split("/").pop();
  const currentPath = window.location.pathname;
  const excludedPaths = ["/ebook/download", "/login", "/rahela-tayyebi/ebook"];
  const isExcluded = excludedPaths.some((path) => currentPath.startsWith(path));

  return (
    // <div className="App">
    //   <header className="App-header">
    //     <img src={logo} className="App-logo" alt="logo" />
    //     <p>
    //       Edit <code>src/App.js</code> and save to reload.
    //     </p>
    //     <a
    //       className="App-link"
    //       href="https://reactjs.org"
    //       target="_blank"
    //       rel="noopener noreferrer"
    //     >
    //       Learn React
    //     </a>
    //   </header>

    // </div>
    // <LandingPage />
    <ThemeProvider theme={theme}>
      <DialogProvider>
        <PopupProvider>
          <div className="App">
            <Mainroutes />
            {!isExcluded && (
              <PopupDialog type={currentSlug} currentPath={currentPath} />
            )}
          </div>
        </PopupProvider>
      </DialogProvider>
    </ThemeProvider>
  );
}

export default App;
