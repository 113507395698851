import React, { useEffect, useRef } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import {
  open_api_with_user_token,
  open_api_without_auth,
} from "../../Utils/network";

const ExpertRedirect = () => {
  const { expertslug, workshopSlug, pageType, itemSlug } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const hasTrackedVisit = useRef(false);

  useEffect(() => {
    const trackVisit = async () => {
      if (hasTrackedVisit.current) return;
      hasTrackedVisit.current = true;

      const token = localStorage.getItem("bearerToken");
      const isUserLoggedIn = token !== null;

      const searchParams = new URLSearchParams(location.search);
      const utmSource = searchParams.get("utm_source") || "direct";
      const utmMedium = searchParams.get("utm_medium") || "unknown";
      const utmCampaign = searchParams.get("utm_campaign") || "general";

      const localData = {
        expert_slug: expertslug ?? "",
        page_url: window.location.href,
        referer_url: document.referrer || "direct",
        utm_source: utmSource,
        utm_medium: utmMedium,
        utm_campaign: utmCampaign,
      };

      try {
        if (isUserLoggedIn) {
          await open_api_with_user_token(token).post(
            "/stats/track-visit/",
            localData
          );
        } else {
          await open_api_without_auth.post("/stats/track-visit/", localData);
        }

        sessionStorage.setItem(
          "expertSession",
          JSON.stringify({
            expertslug: expertslug,
            page_url: window.location.href,
            utm_source: utmSource,
            utm_medium: utmMedium,
            utm_campaign: utmCampaign,
          })
        );

        // Handle redirects based on pageType, itemSlug, and workshopSlug
        if (workshopSlug) {
          navigate(`/workshop/${workshopSlug}`);
        } else if (pageType && itemSlug) {
          switch (pageType) {
            case "blog":
              navigate(`/blogs/${itemSlug}`);
              break;
            case "ebook":
              navigate("/ebook/download");
              break;
            case "bookings":
              navigate(`/bookings/${expertslug}`);
              break;
            default:
              navigate("*"); // Redirect to 404 if pageType is unknown
          }
        } else if (pageType) {
          switch (pageType) {
            case "bookings":
              navigate(`/bookings/${expertslug}`);
              break;
            case "ebook":
              navigate("/ebook/download");
              break;
            default:
              navigate(`/experts/${expertslug}`); // Default redirect to expert details page
          }
        } else {
          navigate(`/experts/${expertslug}`); // Redirect to expert details if no pageType
        }
      } catch (error) {
        console.error("Error:", error);
        navigate("*");
      }
    };

    trackVisit();
  }, [expertslug, workshopSlug, pageType, itemSlug, navigate, location.search]); // Added pageType, itemSlug, and location.search to the dependency array

  return <div>Redirecting...</div>;
};

export default ExpertRedirect;
