import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { usePopup } from "./PopupContext";
import DialogContent from "./DialogContent";

// Import your assets
import backgroundImage from "../../Assets/popup/backgroundImage2.png";
import closeIcon from "../../Assets/popup/closeIcon.svg";
import topicForKidsCover from "../../Assets/popup/topic-for-kids-cover.png";
import { open_api_without_auth } from "../../Utils/network";

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
`;

const Dialog = styled.div`
  position: relative;
  width: 900px;
  height: 600px;
  background: white;
  border-radius: 10px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 768px) {
    width: 90%;
    height: 90%;
    flex-direction: column;
  }
`;

const ImageContainer = styled.div`
  position: relative;
  width: 72%;
  height: 100%;
  background-image: ${({ popupData }) => `url(${popupData.image})`};
  background-size: cover;
  background-position: center;
  margin-right: 57%;

  @media (max-width: 768px) {
    width: 100%;
    height: 30%;
    margin-right: 0;
  }
`;

const CloseButton = styled.button`
  position: absolute;
  top: 20px;
  right: 20px;
  background: none;
  border: none;
  color: white;
  font-size: 20px;
  cursor: pointer;
  background-image: url(${closeIcon});
  background-size: contain;
  background-repeat: no-repeat;
  width: 24px;
  height: 24px;
  z-index: 10;

  @media (max-width: 768px) {
    top: 15px;
    right: 15px;
  }
`;

const PopupDialog = ({ type, currentpath }) => {
  const { isOpen, hidePopup, showPopup } = usePopup();
  const [hasContent, setHasContent] = useState(false);
  const [loading, setLoading] = useState(true);
  const is_expert = currentpath?.includes("experts/") ? true : false;
  const [popupData, setPopupData] = useState();

  // Corrected API URL function
  const getPopupUrl = (is_expert, type) => {
    let baseUrl = `/lead/offer-popups/get-popups/?is_expert=${is_expert}`;
    if (is_expert) {
      baseUrl += `&expertslug=${type}`;
    } else {
      baseUrl += `&workshopslug=${type}`;
    }
    return baseUrl;
  };

  useEffect(() => {
    const popupApiUrl = getPopupUrl(is_expert, type);

    const fetchPopupContent = async () => {
      try {
        setLoading(true);
        const response = await open_api_without_auth.get(popupApiUrl);
        if (
          response.status === 200 ||
          response.status === 201 ||
          response.status === 204
        ) {
          if (response.data) {
            setPopupData(response.data.results[0]);
            setHasContent(true);
          } else {
            setHasContent(false);
          }
        } else {
          setHasContent(false);
        }
      } catch (e) {
        setHasContent(false);
      } finally {
        setLoading(false);
      }
    };

    fetchPopupContent();
  }, [type, showPopup, is_expert]);

  const handleClose = () => {
    hidePopup();
    sessionStorage.setItem("popupSeen", "true");
  };

  return isOpen && hasContent ? (
    <Overlay>
      <Dialog>
        <CloseButton onClick={handleClose} />
        <ImageContainer popupData={popupData} />
        <DialogContent
          type={type}
          hidePopup={hidePopup}
          popupData={popupData}
        />
      </Dialog>
    </Overlay>
  ) : null;
};

export default PopupDialog;
