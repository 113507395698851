import React, { useState } from "react";
import styled from "styled-components";
import { open_api } from "../../Utils/network";

// Import your assets
import contentBackground from "../../Assets/popup/designCurve.svg"; // Adjust the path
import logo from "../../Assets/popup/myburgoLogo.svg"; // Adjust the path

// ContentContainer now uses the hideBg prop to conditionally apply the background
const ContentContainer = styled.div`
  position: absolute;
  top: 51%;
  left: 72%;
  transform: translate(-50%, -50%);
  background: ${({ hideBg }) =>
    hideBg ? "none" : `url(${contentBackground}) no-repeat center center`};
  background-size: cover;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  width: 62%;
  height: 100%;
  border-radius: 10px;

  @media (max-width: 768px) {
    position: relative;
    top: 0;
    left: 0;
    transform: none;
    width: 100%;
    height: 70%;
    border-radius: 0 0 10px 10px;
  }
`;

const Logo = styled.div`
  width: 43%;
  height: 15%;
  background-image: url(${logo});
  background-size: contain;
  background-repeat: no-repeat;

  @media (max-width: 768px) {
    width: 60%;
    height: 10%;
    margin-bottom: 10px;
  }
`;

const Title = styled.div`
  font-size: 25px;
  margin-bottom: 20px;
  text-align: center;

  @media (max-width: 768px) {
    font-size: 20px;
    margin-bottom: 10px;
  }
`;

const Form = styled.form`
  background: white;
  padding: 20px;
  border-radius: 10px;
  width: 73%;

  @media (max-width: 768px) {
    width: 90%;
    padding: 15px;
  }
`;

const Input = styled.input`
  width: calc(100% - 20px);
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
`;

const Button = styled.button`
  width: 100%;
  padding: 10px;
  background: #f27497;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: medium;
  font-weight: 700;
`;

const DialogContent = ({ type, hidePopup, hideBg, popupData }) => {
  const [userDetails, setUserDetails] = useState({
    name: "",
    email: "",
    phone: null,
    type: popupData.type || type,
    expert: popupData.expert,
    workshop: popupData.workshop,
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    open_api
      .post("/lead/leads/", userDetails)
      .then((res) => {
        if (res?.status === 200 || res?.status === 201 || res?.status === 204) {
          setUserDetails({
            name: "",
            email: "",
            phone: null,
          });
          if (popupData.ebook) {
            const link = document.createElement("a");
            link.href = popupData.ebook;

            const fileName = popupData.ebook.split("/").pop();
            link.download = fileName || "ebook.pdf";

            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

            hidePopup();
          } else {
            console.error("Ebook URL is not available");
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserDetails((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  return (
    <ContentContainer hideBg={hideBg}>
      <Logo />
      <Title>{popupData.title}</Title>
      <Form onSubmit={handleSubmit}>
        <Input
          type="text"
          name="name"
          placeholder="Name"
          value={userDetails.name}
          onChange={handleChange}
          required
        />
        <Input
          type="email"
          name="email"
          placeholder="Email"
          value={userDetails.email}
          onChange={handleChange}
          required
        />
        <Input
          type="tel"
          name="phone"
          placeholder="Phone Number"
          value={userDetails.phone}
          onChange={handleChange}
          required
        />
        <Button type="submit">Download the ebook</Button>
      </Form>
    </ContentContainer>
  );
};

export default DialogContent;
