import React, { useState } from "react";
import styled from "styled-components";
import { open_api } from "../../Utils/network";
import { useNavigate } from "react-router-dom"; // Import useNavigate from react-router-dom

// Import your assets
import contentBackground from "../../Assets/popup/designCurve.svg"; // Adjust the path
import logo from "../../Assets/popup/myburgoLogo.svg"; // Adjust the path

const ContentContainer = styled.div`
  background-size: cover;
  color: black;
  font-weight: bold;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  border-radius: 10px;

  @media (max-width: 768px) {
    width: 100%;
    height: auto;
    border-radius: 0 0 10px 10px;
  }
`;

const Logo = styled.div`
  width: 43%;
  height: 15%;
  background-image: url(${logo});
  background-size: contain;
  background-repeat: no-repeat;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    width: 60%;
    height: 10%;
    margin-bottom: 10px;
  }
`;

const Title = styled.div`
  font-size: 25px;
  margin-bottom: 20px;
  text-align: center;

  @media (max-width: 768px) {
    font-size: 20px;
    margin-bottom: 10px;
  }
`;

const Form = styled.form`
  background: white;
  padding: 20px;
  border-radius: 10px;
  width: 90%;

  @media (max-width: 768px) {
    width: 90%;
    padding: 15px;
  }
`;

const Input = styled.input`
  width: calc(100% - 20px);
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
`;

const Button = styled.button`
  width: 100%;
  padding: 10px;
  background: #f27497;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: medium;
  font-weight: 700;
`;

const ErrorText = styled.div`
  color: red;
  font-size: 12px;
  margin-bottom: 10px;
`;

const EbookDialogContent = ({ type, hidePopup, hidebg }) => {
  const [userDetails, setUserDetails] = useState({
    name: "",
    email: "",
    phone: "",
    type: type,
  });
  const [phoneError, setPhoneError] = useState(false); // For phone validation

  const navigate = useNavigate(); // useNavigate hook for redirection

  const handleSubmit = (e) => {
    e.preventDefault();

    // Phone validation (simple non-empty check, you can extend this)
    if (!userDetails.phone || userDetails.phone.trim() === "") {
      setPhoneError(true);
      return;
    }

    // Call the API
    open_api
      .post("home/earlyinterest/", userDetails)
      .then((res) => {
        if (res?.status === 200 || res?.status === 201 || res?.status === 204) {
          setUserDetails({
            name: "",
            email: "",
            phone: "",
          });

          // Trigger the book download
          const link = document.createElement("a");
          link.href = "/topics-to-teach-2-3-years-old-at-home.pdf"; // Path to your book
          link.download = "topics-to-teach-2-3-years-old-at-home.pdf";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);

          // Redirect to the home page after successful download
          setTimeout(() => {
            navigate("/"); // Redirect to home page
          }, 2000); // Small delay to ensure the download starts first
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserDetails((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    // Reset phone error when user starts typing
    if (name === "phone" && phoneError) {
      setPhoneError(false);
    }
  };

  return (
    <ContentContainer hidebg={hidebg}>
      <Logo />
      <Title>
        Download free ebook on "Topics to teach 2-3 year old kids by Rahela"
      </Title>
      <Form onSubmit={handleSubmit}>
        <Input
          type="text"
          name="name"
          placeholder="Name"
          value={userDetails.name}
          onChange={handleChange}
          required
        />
        <Input
          type="email"
          name="email"
          placeholder="Email"
          value={userDetails.email}
          onChange={handleChange}
          required
        />
        <Input
          type="tel"
          name="phone"
          placeholder="Phone Number"
          value={userDetails.phone}
          onChange={handleChange}
          required
        />
        {phoneError && <ErrorText>Please enter a valid phone number</ErrorText>}
        <Button type="submit">Download the ebook</Button>
      </Form>
    </ContentContainer>
  );
};

export default EbookDialogContent;
