import React, { useEffect, useState } from "react";
import BookingSession from "../../Components/BookingSession/BookingSession";
import { useParams } from "react-router-dom";
import { open_api } from "../../Utils/network";
import { useLocation } from "react-router-dom";
import LoadingOverlay from "../../Components/Dialog/LoadingOverlay";
import FAQ from "../../Components/Faq/FAQ";
import "./BookingSessionPage.css";

const BookingSessionPage = () => {
  const [expertDetails, setExpertsDetails] = useState(null);
  const [packages, setPackages] = useState(null); // Set initial state to null to track loading state
  const [loading, setLoading] = useState(true); // Loading state to track data fetching
  const { expertslug } = useParams();
  const location = useLocation();

  // Destructure location.state with default values
  const {
    bypassPurchase = false,
    bypassPackage = false,
    bookedPackageId = null,
    purchaseId = null,
    purchasedUserId = null,
    duration = null,
    isDataRequired = false,
  } = location.state || {};

  // Fetch expert details and packages
  useEffect(() => {
    const getExpertDetails = async () => {
      try {
        const res = await open_api.get(`profiles/experts/${expertslug}/`);
        if (res?.status === 200 || res?.status === 201 || res?.status === 204) {
          setExpertsDetails(res?.data);

          // Fetch packages after expert details are available
          const packageRes = await open_api.get(
            `/session/package_list?expert=${res?.data.id}`
          );
          setPackages(packageRes?.data?.data);
        }
      } catch (error) {
        console.error("Error fetching expert details:", error);
      } finally {
        setLoading(false); // Stop loading when both expert details and packages are fetched
      }
    };

    getExpertDetails();
  }, [expertslug]);

  // Render loading spinner or message if data is being fetched
  if (loading) {
    return <LoadingOverlay />;
  }

  const faqs = [
    {
      id: 1,
      title: "Can I cancel a booking",
      answer: [
        {
          id: 1,
          answer:
            "No, you cannot cacel a booking, For support contact hello@myburgo.com or give a call to +91 7710048212",
        },
      ],
    },
    {
      id: 2,
      title: "Can I reschedule a booking",
      answer: [
        {
          id: 1,
          answer:
            "Currently we dont support rescheduling a booking. however if you wish to reschedule a booking you can reach out to hello@myburgo.com or +91 7710048212.",
        },
      ],
    },
    // Add more FAQ items here
  ];

  // Once data is fetched, render the BookingSession component
  return (
    <div>
      <BookingSession
        expertDetails={expertDetails}
        packages={packages}
        bypassPackage={bypassPackage}
        bypassPurchase={bypassPurchase}
        bookedPackageId={bookedPackageId}
        purchaseId={purchaseId}
        purchasedUserId={purchasedUserId}
        duration={duration}
        isDataRequired={isDataRequired}
      />
      <div className="section-container">
        <FAQ data={faqs} />
      </div>
    </div>
  );
};

export default BookingSessionPage;
