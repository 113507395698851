// src/components/ExpertSession/ExpertSessionStyles.js

import styled from "styled-components";

export const LeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  max-width: 600px;
  margin: 0 auto;
`;

export const Header = styled.h1`
  font-size: 24px;
  margin-bottom: 20px;
`;

export const ExpertInfo = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  padding: 20px;
  background: #f9f9f9;
  border-radius: 8px;
`;

export const ExpertImage = styled.img`
  width: 80px;
  height: 80px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 20px;
`;

export const ExpertDetails = styled.div`
  flex: 1;

  .divider {
    border-top: 1px solid #ddd;
    margin: 8px 0;
  }
`;

export const ExpertName = styled.h2`
  font-size: 20px;
  color: #7a53ff;
  margin-bottom: 0;
`;

export const ExpertDescription = styled.p`
  font-size: 14px;
  color: #757575;
  margin-top: 0;
`;

export const PackageHeader = styled.h2`
  font-size: 18px;
  margin-bottom: 20px;
`;

export const PackageContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: center; // Center the package cards within the container
  background: white;
  padding: 14px;
  border-radius: 10px;

  @media only screen and (max-width: 767px) and (min-width: 320px) {
    flex-direction: column;
  }
`;

export const PackageCard = styled.div`
  flex: 1 1 calc(50% - 10px); // Each card takes up 50% width minus the gap
  max-width: calc(42% - 10px);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 15px;
  border-radius: 8px;
  background: #fff;
  border: 1px solid #ccc;
  text-align: center;
  cursor: pointer;
  &:hover {
    border-color: #ff67a1;
    background: #ff67a1;
    color: #fff;

    p {
      color: #fff;
    }
  }

  &.selected {
    background: #ff67a1;
    border-color: #ff67a1;
    color: #ffffff;

    p {
      color: #ffff;
    }
  }

  @media only screen and (max-width: 767px) and (min-width: 320px) {
    max-width: none;
  }
`;

export const PackageTitle = styled.h3`
  font-size: 16px;
  margin-bottom: 10px;
`;

export const PackagePrice = styled.p`
  font-size: 14px;
  color: black;

  &.selected {
    color: #ffffff;
  }

  .original-price {
    text-decoration: line-through;
    color: gray;
    margin-right: 8px;
  }

  .discounted-price {
    font-weight: bold;
  }
`;

export const DialogContainer = styled.div`
  background: #e7e7e7;
  border-radius: 8px;
  overflow: hidden;
  max-width: 435px;
  margin: auto;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

  @media only screen and (max-width: 767px) and (min-width: 320px) {
    flex-direction: column;
    box-shadow: none;
    background: white;
  }
`;

export const Card = styled.div`
  background: #fff;
  padding: 16px;
  border-radius: 8px;
  margin-bottom: 16px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

  h3 {
    font-size: 16px;
    font-weight: 600;
  }

  @media only screen and (max-width: 767px) {
    h3 {
      font-size: 14px;
    }
  }
`;

export const InnerCardContainer = styled.div`
  display: flex;
  justify-content: space-evenly;

  @media only screen and (max-width: 767px) {
    flex-direction: column;
  }
`;

export const InnerCard = styled.div`
  background: #ff67a1;
  border-radius: 8px;
  padding: 16px;
  flex: 0 1 48%;
  display: flex;
  flex-direction: column;
  color: #fff;
  text-align: center;
  margin-bottom: 5px;

  p {
    margin: 8px 0;
    font-size: 14px;
    font-weight: 600;
  }

  @media only screen and (max-width: 767px) {
    flex: 1 1 100%;
    margin-bottom: 16px;
  }
`;

export const ChangeLink = styled.a`
  color: #007bff;
  text-decoration: none;
  cursor: pointer;
  font-size: 14px;
  margin-top: 8px;
  display: flex;
  align-self: center;
  font-weight: 500;

  &:hover {
    text-decoration: underline;
  }

  @media only screen and (max-width: 767px) {
    font-size: 12px;
  }
`;

export const InnerCardFlex = styled.a`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin: 8px;
`;

export const PayButton = styled.button`
  background: #ff67a1;
  color: #fff;
  padding: 12px 16px;
  border: none;
  cursor: pointer;
  font-size: 16px;
  text-align: center;
  width: 100%;
  font-weight: 700;
  border-radius: 0;

  &:hover {
    background: #ff79a5;
  }

  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;

  @media only screen and (max-width: 767px) {
    padding: 20px;
  }

  @media only screen and (min-width: 768px) {
    padding: 20px;
  }
`;
